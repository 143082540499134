<template>
  <div>
    <clinicList
      :canEdit="check_has_permission('updateClinicManagement')"
      editRoute="AdminClinicEdit"
      :canCreate="true"
      createRoute="AdminClinicCreate"
      :canViewDetail="check_has_permission('viewClinicManagement')"
      viewRoute="AdminClinicDetail"
    />
  </div>
</template>

<script>
import clinicList from "../../components/clinics/vendorAssociatedClinicList.vue";

export default {
  components: {
    clinicList
  },
  data: () => ({})
};
</script>
